import React from 'react';
import Icon from './Icon';
import Button from './Button';
import { motion } from "framer-motion"

const ButtonSelect = (props) => {
  return (
    <motion.div
    initial={{ opacity: 0}}
    animate={{ opacity: 1}}
    transition={{ duration: 0.5 }}
    
    style={{height: '550px'}} className='absolute inset-x-0 text-center bottom-0'>
        <Icon icon={props.icon}></Icon>
        <Button url={props.url} text={props.buttonText}></Button>
    </motion.div>
  )
}

export default ButtonSelect;
