import { useState } from "react";
import useSound from 'use-sound';

import correct from '../../../sfx/correct.mp3';
import incorrect from '../../../sfx/incorrect.mp3';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"

function QuizBtn(props) {

    const [color, setColor] = useState('#C5D8E6')
    const [correctPlay] = useSound(correct);
    const [incorrectPlay] = useSound(incorrect);

    const navigate = useNavigate();

    const _handleClick = () => {
        if(props.answer.correct){
            setColor("#2eb509");
            correctPlay();
            setTimeout(() => {
                if(props.score >= props.total){
                    navigate("/quizz/win");
                } else{
                    props.setScore(props.score + 1);
                    props.setQuestion(props.questions[props.score]);
                }
            }, 1000);
        } else {
            incorrectPlay();
            setColor("#851d0b");
        }
      
    }

    return (
        <motion.button initial={{opacity: 0}} animate={{ opacity: 1}} transition={{delay:props.delay}}onClick={_handleClick} style={{background:color, borderRadius:"40px", fontSize:"35px", color:"#333333"}} className="bg-amber-900 flex p-5 justify-center flex-grow basis-5/12 m-2">{props.text}</motion.button>
    );
}

export default QuizBtn;