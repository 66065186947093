import React from 'react';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
const GameButton = () => {
  const navigate = useNavigate();

  function goQuiz() {
      navigate('/quizz');
  }

  const dragEvent = (e, data)=>{
      if(Math.abs(data.y) >= 115) {
        goQuiz()
      }
  }

    const { t } = useTranslation();
  return (
    <motion.div initial={{bottom:-200}} animate={{ bottom: 0}} transition={{duration:0.5}} className='fixed z-40 inset-x-0 bottom-0 flex justify-center'>
      <div className='bg-white w-fit ' style={{ borderTopLeftRadius:"15px", borderTopRightRadius:"15px"}}>
        <Draggable onDrag={dragEvent} axis="y" bounds={{top:-115, bottom:0}}>
            <div onClick={goQuiz} style={{borderTopLeftRadius:"15px", color:"#333333", borderTopRightRadius:"15px", fontSize:'65px'}} className='relative flex-wrap flex-row gotica-font flex w-80 justify-center items-center text-center h-36 mx-auto z-40 bg-white'>
                <img className="pointer-events-none" src="/images/rodykle.png" style={{width:'60px'}} alt="game"/><br/>
                <button  onTouchEnd={goQuiz} className='basis-full mb-4' style={{letterSpacing: "-2px"}}>
                  {t('Žaidimas')}
                </button>
            </div>
        </Draggable>
      </div>
    </motion.div>
  )
}

export default GameButton;
