import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';
function Win() {

    const navigate = useNavigate();
    function goHome() {
      navigate('/');
    }

    return (
    <div style={{width:"1920px", height:"1080px"}}>
       <img src="/images/fonas.jpg" className="w-full pointer-events-none" alt="img"/>
       <motion.div
            initial={{x:-1200}}
            animate={{x:0}}
            transition={{ duration: 0.5 }} className="fixed text-center gotica-font text-white"
            style={{top:"200px", left:"20px", fontSize:"144px", width:'900px', textShadow:'2px 4px 6px rgba(0, 0, 0, 1)'}}>
        Sveikiname sužinojus daugiau apie Simonaitytę!
       </motion.div>
       <motion.div
            initial={{y:200}}
            animate={{y:0}}
            transition={{ duration: 0.5 }}

            onClick={goHome}
            style={{borderRadius: "20px"}} className='fixed bottom-10 right-12 flex justify-center items-center mb-5 w-24 h-24 bg-white py-3 px-3 '>
            <img className='pointer-events-none' src="/images/house.png" style={{width:'90px'}} alt="house" />
        </motion.div>  
    </div>
    );
}

export default Win;