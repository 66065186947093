import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../../components/GoBackButton'
import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Book() {

    const {id} = useParams();

    const { i18n } = useTranslation();
    const [book, setBook] = useState({
        name: "Knyga",
        description: "Aprašymas",
        image:""
    });
    const navigation = useNavigate();

    const loadData = () => {
        axios.get('/bookies.json').then((response) => {
            if(response.data[id] === undefined){
                navigation('/simonaityte/creativity')
            } else {
                setBook(response.data[id])
            }
        }).catch((error)=>{
            console.error(error);
        });
    };

    useEffect(loadData, [navigation, id]);

    return (
        <div className="w-full h-screen " style={{background:'#F0C66B'}}>
            <div style={{fontSize:'80px'}} className="w-full pt-20 text-center gotica-font">
                {book.name}
            </div>
            <div className="flex columns-2 mt-12">
                <div className="w-1/4">
                    <div className="self-center w-full">
                        <img className="mx-auto" src={book.image} alt="book"/>
                    </div>
                </div>
                <div className="w-3/4 text-black text-3xl px-10">
                    {book[`description_${i18n.language}`]}
                </div>
            </div>
            <div className='fixed bottom-16 right-16'>
                <GoBackButton link="/simonaityte/creativity"/>
            </div>
        </div>
    );
}

export default Book;