import React from 'react';
import ButtonSelect from './ButtonSelect';

const Divider = (props) => {
  return (
    <div className="w-3/6 m-0 inline-block relative float-left">
      <img src={props.bg} alt="img"></img>
      <ButtonSelect url={props.url} buttonText={props.buttonText} icon={props.icon}></ButtonSelect>
    </div>
  )
}

export default Divider;
