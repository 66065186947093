import React from 'react';
import { useState, useEffect } from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import Button from './components/Button';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import AudioButton from '../../components/AudioButton';
import { useRef } from 'react';

const Simonaityte = () => {

    const [play, setStatus] = useState(false);
    const [talk, setTalk] = useState(false);

    const navigate = useNavigate();
    function goHome() {
      navigate('/');
    }

    const [text, setText] = useState(`
    Ieva Simonaitytė - Klaipėdos krašto rašytoja, autobiografinių apysakų ir romanų kūrėja, aprašiusi buities ir išskirtinius lietuvininkų likimus. 
    Ieva Simonaitytė gimė 1897m. sausio 23d. Vanagų kaime, Klaipėdos apskrityje. Penkerių metų susirgo kaulų džiova, dėl to negalėjo lankyti mokyklos. Skaityti ir rašyti ją išmokė motina. Jaunystėje vaikščiodama su lazdomis ji turėjo dirbti: ganyti svetimas žąsis, prižiūrėti mažus vaikus. 1912 – 1914m. I.Simonaitytė gydėsi Angerburgo sanatorijoje Vokietijoje. Iš ten grįžo sustiprėjusi. Vertėsi siuvėjos amatu, įsitraukė į lietuvišką veiklą: dirbo Vanagų lietuvių jaunimo sambūryje „Eglė“, bendradarbiavo ‚,Tilžės keleivyje“ ir kitoje Mažosios Lietuvos lietuviškoje spaudoje.
    1921m. I.Simonaitytė persikėlė į Klaipėdą. Remiama A. Bruožio baigė vakarinius mašinraščio ir stenografijos kursus. Dirbo Lietuvos konsulate, ,,Ryto“ spaustuvėje korektore, ,,Prūsų Lietuvių balso“ redakcijoje, Klaipėdos krašto direktorijoje, vėliau Seimelio raštinėje mašininke ir vertėja.
    I.Simonaitytės kūrybiniai polinkiai išryškėjo labai anksti. Kūrinėliai buvo spausdinami Mažosios Lietuvos laikraščiuose, tačiau iki 1935m. I.Simonaitytės vardas literatūros pasaulyje buvo nežinomas. Tais metais I.Simonaitytei už romaną ,,Aukštujų Šimonių likimas“ paskirta Lietuvos valstybinė literatūros premija. Nuo 1936m. I.Simonaitytė atsidėjo vien literatūriniam darbui, jai buvo paskirta pensija. 
    Klaipėdoje gyveno iki 1939 m. 1938-1939 m. rašytoja gydėsi Šveicarijoje. Po to į Klaipėdą nebegrįžo, trumpam apsigyveno Telšiuose, vėliau Kaune.  Nuo 1963 m. gyveno Vilniuje, o vasarodavo Priekulėje, kur 1961 m. buvo pasistačiusi vasarnamį.
    I. Simonaitytė nors ir būdama silpnos sveikatos, nuolat rašė, o jos kūryba buvo leidžiama ganėtinai dažnai: „Pavasarių audroj“ (1938), „Vilius Karalius“ (1939, 1956), „Be tėvo“ (1941), „Apysakos“ (1948),  „Pikčiurnienė“ (1953), „… O buvo taip“ (1960), „Ne ta pastogė“ (1962), „Nebaigta knyga“ (1965), „Gretimos istorijėlės“ (1968). Rašytojos kūryba buvo verčiama į latvių, rusų, vokiečių, anglų, lenkų, kinų, baltarusių, rumunų, kazachų, ispanų, prancūzų, čekų kalbas.
    I. Simonaitytė mirė 1978 m. rugpjūčio 27 d. Vilniuje. Rašytoja palaidota Vilniaus Antakalnio kapinių Menininkų kalnelio papėdėje.
    `);

    const { t, i18n } = useTranslation();
    const videoRef = useRef(null)
    useEffect(() => {
        if(i18n.language === "en"){
            setText(`
            Ieva Simonaitytė is a writer from the Klaipėda region, author of autobiographical short stories and novels, who has described the domestic life and the extraordinary fates of Lithuanians.
            Ieva Simonaitytė was born on 23 January 1897 in the village of Vanagai, Klaipėda County. At the age of five, she fell ill with tuberculosis, which prevented her from attending school. Her mother taught her to read and write. In her youth, she had to work with walking sticks: shepherding geese, looking after small children. From 1912 to 1914, Simonaitytė was treated at the Angerburg Sanatorium in Germany. She returned from there strengthened. She worked as a seamstress, was involved in Lithuanian activities: she worked in the Vanagai Lithuanian youth association "Eglė", collaborated in "Tilžės keleivis" and other Lithuanian press of Lithuania Minor.
            In 1921, Simonaitytė moved to Klaipėda. With the support of A. Bruožis, she completed evening courses in typing and shorthand. She worked at the Lithuanian Consulate, at the "Rytas" printing house as a proofreader, at the editorial office of the "Prūsų lietuvių balsas", at the Klaipėda Regional Directorate, and later at the Seimas office as a typist and translator.
            I. Simonaitytė's creative inclinations emerged very early. Her works were published in the newspapers of Lithuania Minor, but until 1935 Simonaitytė's name was unknown in the world of literature. In that year, Simonaityte was awarded the Lithuanian State Prize for Literature for her novel "Aukštujų Šimonių likimas". From 1936 onwards, Simonaitytė devoted herself exclusively to literary work and was granted a pension.
            She lived in Klaipėda until 1939. After that, she did not return to Klaipėda, but settled for a short time in Telšiai, and later in Kaunas.  From 1963 she lived in Vilnius, and spent her summers in Priekulė, where she built a summer house in 1961.
            I. Simonaitytė, although in poor health, wrote regularly, and her work was published quite often: "Pavasarių audroj" (1938), "Vilius Karalius" (1939, 1956), "Be tėvo" (1941), "Apysakos" (1948), "Pikčiurnienė" (1953), "... O buvo taip" (1960), "Ne ta pastogė" (1962), "Nebaigta knyga" (1965), "Gretimos istorijėlės" (1968). Her work has been translated into Latvian, Russian, German, English, Polish, Chinese, Belarusian, Romanian, Kazakh, Spanish, French, Czech languages.
            I. Simonaitytė died in Vilnius, Lithuania, on 27 August 1978. The writer is buried at the bottom of the “Meninkų kalnelis” in Vilnius Antakalnis Cemetery.            
            `)
        }
        const audio = new Audio(`/audio/${i18n.language}/01.mp3`)
        if(talk === true) {
            videoRef.current.pause()
            setStatus(false)
            audio.play()
        } else {
            audio.pause()
        }

        return () => {
            audio.pause();
        };

    }, [setText, i18n, talk, play]);

    const _handleClick = (e) => {
        if(talk === true) {
            setTalk(false);
        }
        if(play){
            e.currentTarget.pause();
            setStatus(false)
        } else {
            e.currentTarget.play()
            setStatus(true)
        }
    }

    return (
        <div className='relative'>
            <div className='w-1/2 inline-block float-left h-screen relative'>
                <video poster="/images/simonaityte.jpg" ref={videoRef} onClick={e=>{ _handleClick(e)}} onEnded={e=>{
                    e.currentTarget.load();
                    setStatus(false)
                }}>
                    <source src="/videos/Video.mp4" type="video/mp4"/>
                </video>
                {!play ? <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.5}} style={{pointerEvents:'none'}} className='absolute h-screen top-0 left-0 w-full text-red-500 z-30 flex justify-center items-center'>
                        <div style={{background:"rgba(255,255,255,0.5)"}} className='w-48 h-48 text-center flex justify-center items-center rounded-full'>
                            <FontAwesomeIcon style={{fontSize:"100px"}} className='text-white opacity-100 ml-4' icon={faPlay} />
                        </div>
                </motion.div> : null}
            </div>
            <div style={{background:"#6F9EC1"}} className='w-1/2 inline-block float-left h-screen relative'>
                <AudioButton isPlaying={talk} setTalk={setTalk}/>
                <motion.div initial={{y: -200 }} animate={{ y: 0}} transition={{duration:0.5}} style={{color:"#293B48", fontSize:'80px'}} className='w-full text-center gotica-font mt-12'>
                    Ieva Simonaitytė
                </motion.div>
                <motion.div initial={{x: 1200}} animate={{ x: 0}} transition={{duration:0.5}} style={{"height":"680px"}} className='w-10/12 mt-2 text-center text-white text-2xl px-2 relative overflow-y-scroll mx-auto'>
                    {text}
                </motion.div>
                <div className='absolute inset-x-0 bottom-0'>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className='w-full px-3 mb-6'>
                        <ul className='list-none px-0 text-center'>
                            <Button text={t('Kūryba')} link="/simonaityte/creativity"/>
                            <Button text={t('Pokalbiai')} link="/simonaityte/talks"/>
                        </ul>
                    </motion.div>
                    <div className='w-10/12 mx-auto mt-2'>
                        <motion.div
                            initial={{y:200}}
                            animate={{y:0}}
                            transition={{ duration: 0.5 }}
                            
                            onClick={goHome}
                            style={{borderRadius: "20px"}} className='float-right flex justify-center items-center mb-5 w-20 h-20 bg-white py-3 px-3 '>
                            <img className='pointer-events-none' src="/images/house.png" style={{width:'80px'}} alt="house" />
                        </motion.div>  
                    </div>
                </div>
                
            </div>
        </div>
        )
}
export default Simonaityte;