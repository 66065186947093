import { useNavigate } from "react-router-dom";

function Button(props) {

    const navigate = useNavigate();
    function _handleClick() {
      navigate(props.link);
    }

    return (
        <li className='list-none inline px-4'>
            <button style={{borderRadius:"40px", fontSize:'60px', color:"#333333"}} onClick={_handleClick} className='py-4 px-14 gotica-font bg-white'>
                {props.text}
            </button>
        </li>
    );
}

export default Button;