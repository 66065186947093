import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './views/Home/Home'
import Simonaityte from './views/Simonaityte/Simonaityte';
import Sculpture from './views/Sculpture/Sculpture';
import Quizz from './views/Quizz/Quizz';
import Win from './views/Quizz/Win';
import Talks from './views/Simonaityte/views/Talks/Talks';
import Creativity from './views/Simonaityte/views/Creativity/Creativity';
import Book from './views/Simonaityte/views/Book/Book';
import { useEffect } from 'react';
function App() {

  useEffect(() => {
    let timeout;

        const goToOverlay = () => {
          if(window.location.pathname !== "/"){
            window.location = "/";
          }
        }
        const restartAutoReset = () => {
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                goToOverlay();
            }, 120000);
          };
    
        const onActivity = () => {
            restartAutoReset();
        };
        
        restartAutoReset();

        window.addEventListener('mousemove', onActivity);
        window.addEventListener('touchstart', onActivity);

        return () => {
          clearTimeout(timeout);
          window.removeEventListener('mousemove', onActivity);
          window.removeEventListener('touchstart', onActivity);
      };
  }, []);

  return (
    <div>
      <div className='class-cover flex fixed inset-0 bg-slate-300' style={{zIndex: '9000'}}>
        <div className='text-6xl self-center text-center w-full'>
            Šis turinys pritaikytas įrenginiams kurių rezoliucija yra 1920x1080px
        </div>
      </div>
      <Router>
        <div className="relative touch-none" style={{width:"1920px", height:'1080px'}}>
          <div>
              <Routes>
                <Route path="">
                  <Route index element={<Home />} />
                  <Route path="simonaityte">
                      <Route index element={<Simonaityte />} />
                      <Route path="talks" element={<Talks />}/>
                      <Route path="creativity">
                        <Route index element={<Creativity />} />
                        <Route path="book/:id" element={<Book />}/>                
                      </Route>
                  </Route>
                  <Route path="sculpture" element={<Sculpture />}/>
                  <Route path="quizz">
                    <Route element={<Quizz />} index />
                    <Route path="win" element={<Win />} />
                  </Route>
                </Route>                
              </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
