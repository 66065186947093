import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faVolumeOff } from '@fortawesome/free-solid-svg-icons'

const AudioButton = ({isPlaying, setTalk}) => {
  return (
    <div onClick={()=>{
      setTalk(!isPlaying)
    }} className="fixed top-10 right-10 w-24 text-center z-50">
        <FontAwesomeIcon style={{fontSize:"65px"}} className='text-white' icon={ isPlaying ? faVolumeUp: faVolumeOff} />
    </div>
  )
}

export default AudioButton;
