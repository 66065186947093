import Item from "./components/ListItem";

import GoBackButton from '../../components/GoBackButton'
import { useTranslation } from 'react-i18next'
import { motion } from "framer-motion"
import { useState } from "react";

function Talks() {
    const {t, i18n } = useTranslation();

    const [playId, setPlayId] = useState(0);
    

    return (
        <div className="flex columns-2" style={{background:"#6F9EC1"}}>
            <div className=" flex-col w-1/2">
                <img src="/images/autore.jpg" alt="autore"/>
            </div>
            <div className=" flex-col w-1/2">
                <div className="w-full text-center gotica-font mt-16" style={{fontSize:"90px", color:"#333333", lineHeight:"80px", minHeight:"150px"}}>
                    {t('Pokalbiai su Simonaityte')}
                </div>
                
                <div style={{height:"760px"}} className=" relative overflow-y-auto w-11/12 mx-auto">
                    <Item delay="0.2" playid="1" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/02.mp3`} text={t('Apie vaikystę')}/>
                    <Item delay="0.4" playid="2" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/03.mp3`} text={t('Apie Ligą')}/>
                    <Item delay="0.4" playid="3" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/01.mp3`} text={t('Apie pirmąją meilę')}/>
                    <Item delay="0.6" playid="4" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/04.mp3`} text={t('Apie sudaužytą meilės širdį')}/>
                    <Item delay="0.8" playid="5" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/05.mp3`} text={t('Apie vasarojimą Priekulėje')}/>
                    <Item delay="1"   playid="6" currentPlaying={playId} setPlayId={setPlayId} audio={`/audio/${i18n.language}/talks/06.mp3`} text={t('Apie meilę rožėms')}/>
                </div>
                <motion.div initial={{y:200}} animate={{ y: 0}} transition={{duration:0.5, delay:1.2}} className="text-right pr-16">
                    <GoBackButton link="/simonaityte"/>
                </motion.div>
            </div>
        </div>
    );
}

export default Talks;