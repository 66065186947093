import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion'
import AudioButton from '../../components/AudioButton'

const Simonaityte = () => {


    const [text, setText] = useState(`1997 m. Priekulės miesto centre buvo atidengtas paminklas rašytojai Ievai Simonaitytei „Šventvakarių Evė“. Paminklo autorė - skulptorė D. Matulaitė, architektai: Rimantas Buivydas, Jūras Balkevičius. Paminklas įprasmina ne tik vieną iš iškiliausių Klaipėdos krašto asmenybių, bet kartu tai paminklas Mažosios Lietuvos krašto istorijai atminti. Ieva Simonaitytė buvo šio krašto dukra ir visas jos gyvenimas bei kūryba buvo glaudžiai susipynę.Kaip teigia pati skulptorė šis paminklas yra „mano skolos gražinimas šitam kraštui. Paminklinė Ievos Simonaitytės skulptūra - tarsi ąžuolo kamienas atpustytomis šaknimis... Jos figūra - tarsi to kamieno atžala, kaip krikštas, kaip šio krašto gamtos ir kultūros sąveikos išugdytas talentas. Asmenybei skirto paminklo statusą transformuoju į mito žyniškąjį prasmių kodą. Paminklai erdvėje veikia ne literatūrine kalba, o erdvės ir tūrių sąveikos energija, užkoduotų prasmių, simbolių kalba, per tą ar kitą asmenybę primenantį asociatyvųjį pavidalą...Paminklą Ievai Simonaitytė suvokiu ir kaip paminklą tai žemei kurioje ji išaugo, sukaupė, subrandino ir "Aukštųjų Šimonių likimu", "Vilniumi Karaliumi" išreiškė dvasinę šitos žemės energiją, giluminį laiko regėjimą. "Šventvakarių Ėvė" - Šventvakariai, šventi vakarai, šventi Lietuvos vakarai... varde užkoduotas paminklo prasmės.`);
    const [talk, setTalk] = useState(false);

    const { i18n } = useTranslation();

    useEffect(() => {
        if(i18n.language === "en"){
            setText(`In 1997, a monument to the writer Ieva Simonaityte "Šventvakarių Evė" was unveiled in the centre of Priekulė. The monument was designed by sculptor D. Matulaitė, architects Rimantas Buivydas and Jūras Balkevičius. The monument not only commemorates one of the most prominent personalities of the Klaipėda region, but also commemorates the history of the region of Lithuania Minor. Ieva Simonaitytė was a daughter of this region and her whole life and work were closely intertwined. As the sculptor herself says, this monument is "a repayment of my debt to this land. The memorial sculpture of Ieva Simonaitytė is like an oak tree trunk with its roots revealed... Her figure is like an offspring of that trunk, like a baptism, like a talent nurtured by the interaction of nature and culture in this land. I transform the status of a monument dedicated to a personality into a mythical code of meanings. ”Monuments do not operate in space in a literary language, but in the energy of the interaction of space and volumes, in the language of coded meanings, symbols, through the associative form of this or that personality...I perceive the monument to Ieva Simonaitytė also as a monument to the land where she grew up, accumulated, matured, and expressed the spiritual energy of this land, the deep vision of time, in  Aukštųjų Šimonių likimas", " Vilius Karalius"."Šventvakarių Ėvė" (The Maiden of the Holy Nights) - Holy Nights, Holy Evenings, Holy Lithuanian Evenings... The name encodes the meaning of the monument.`)
        }

        const audio = new Audio(`/audio/${i18n.language}/02.mp3`)
    
        if(talk === true) {
            audio.play()
        } else {
            audio.pause()
        }

        return () => {
            audio.pause();
        };

    }, [setText, i18n, talk]);


    const navigate = useNavigate();
    function goHome() {
      navigate('/');
    }
    return (
        <div className='relative'>
            <div style={{background:"#F0C66B"}} className='w-1/2 inline-block float-left h-screen relative'>
                <AudioButton isPlaying={talk} setTalk={setTalk}/>
                <motion.div
                    initial={{y:-200}}
                    animate={{y:0}}
                    transition={{ duration: 0.5 }}
                            
                    style={{color:"#8E631E", fontSize:'90px'}} className='w-full mt-10 text-center gotica-font'>
                    Švetvakarių Ėvė
                </motion.div>
                <motion.div
                    initial={{x:-1200}}
                    animate={{x:0}}
                    transition={{ duration: 0.5 }}
                    
                    style={{"height":"700px", lineHeight:"30px"}} className='w-10/12 mt-20 text-black text-2xl text-center px-2 relative overflow-y-auto mx-auto'>
                    {text}
                </motion.div>
                <motion.div
                    initial={{y:200}}
                    animate={{y:0}}
                    transition={{ duration: 0.5 }}
                    
                    className='w-10/12 mx-auto mt-12'>
                    <div onClick={goHome} style={{borderRadius: "15px"}} className='float-right bg-white py-3 px-3 '>
                        <img className='pointer-events-none' src="/images/house.png" style={{width:'60px'}} alt="house" />
                    </div>  
                </motion.div>
            </div>
            <div className='w-1/2 inline-block float-left h-screen relative'>
                <img src='/images/statue.jpg' alt="img"></img>
            </div>
        </div>
        )
}
export default Simonaityte;