import React, { useState } from 'react';
import { useEffect } from 'react';

function AudioBtn(props) {

    const speaker = "/images/audio-speaker.png"
    const play = "/images/play-button.png"

    const [icon, setIcon] = useState(play);

    const _handleClick = ()=>{
        if(props.isPlaying){
            setIcon(play);
            props.setPlay(false);
        } else {
            setIcon(speaker);
            props.setPlay(true);
            props.setPlayId(props.playid)
        }
    }

    useEffect(() => {
        if(props.playid !== props.currentPlaying) {
            props.setPlay(false);
            setIcon(play);
        }

    }, [props]);

    return (
        <div className="absolute right-7 bottom-5">
            <img src={icon} onClick={_handleClick} style={{width:'45px'}} alt="Audio"/>
        </div>
    );
}

export default AudioBtn;