import WaveSurfer from "wavesurfer.js";
import {useRef, useEffect} from 'react'
import { motion } from "framer-motion"

function Waveform({isPlaying, audio}) {
    const waveformRef = useRef();

    useEffect(() => {
        if (waveformRef.current) {
            const wavesurfer = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "#51748E",
                progressColor : "#333333",
                barWidth: 2,
                hideScrollbar:true,
                interact:false,
                height: 50,
                width: 80,
            });
            wavesurfer.load(audio)

            wavesurfer.on("ready", ()=>{
                if(isPlaying){
                    wavesurfer.play();
                }
            });
        return () => wavesurfer.destroy()
    }
    }, [isPlaying, audio]);
    
    return(
        <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.8}} style={{width:'700px', display: isPlaying ? 'block' : 'none' }} ref={waveformRef}/>
    )
}

export default Waveform;