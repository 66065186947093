import QuizBtn from "./components/QuizzButton";
import GoBackButton from "../Simonaityte/components/GoBackButton"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { motion } from "framer-motion"

function Quizz() {

    const { i18n} = useTranslation();

    const [score, setScore] = useState(1);

    const [questions, setQuestions] = useState([]);

    const [total, setTotal] = useState(0);

    const [question, setQuestion] = useState({
        question: "klausimas",
        answers:[],
        img:""
    });

    const loadQuizzQuestions = () => {
        const lng = i18n.language;

        console.log(lng)
        axios.get(`/questions/${lng}/questions.json`).then((response)=>{
            console.log(response.data)
            setQuestions(response.data);
            setQuestion(response.data[0]);
            setTotal(response.data.length);
        });
    }
    useEffect(loadQuizzQuestions, [i18n, setQuestions, setQuestion]);


    return (
        <div className="flex flex-col h-screen">
            <div className="relative h-3/6 bg-slate-400 overflow-hidden">
                <img src={`questions/images/${question.img}`} style={{top:"-50%"}} alt={question.question}/>
            </div>
            <div style={{background:"#6F9EC1"}} className="h-3/6 bg-blue-500">
                <motion.div 
                initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.5}}
                style={{fontSize:"60px", height:"150px", lineHeight:"100%", color:"#333333"}} className="w-full px-12 text-center mt-5 overflow-hidden ">
                {score}. {question.question}<br/>
                </motion.div>
                <div className="flex flex-wrap flex-row w-8/12 mx-auto mt-5">
                    {question.answers.map((answer, index)=>(
                        <QuizBtn delay={index*0.1} total={total} key={score*10+index} setQuestion={setQuestion} setScore={setScore} questions={questions} score={score} answer={answer} text={answer.title}/>
                    ))}
                </div>
            </div>
            <div className='fixed top-16 right-16'>
                <GoBackButton link="/"/>
            </div>
        </div>
    );
}

export default Quizz;