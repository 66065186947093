import { useNavigate } from "react-router-dom";

function GoBackButton(props) {

    const navigate = useNavigate();
    const _handleClick = () => {
        
        navigate(props.link);
    }

    return (
        <button type="button" style={{borderRadius:'25px'}} className="bg-white px-5 py-2" onClick={_handleClick}>
            <img src="/images/arrow.png" style={{width:"45px"}} alt="arrow" />
        </button>
    );
}

export default GoBackButton;