import { useState } from 'react';
import { useEffect } from 'react';
import GoBackButton from '../../components/GoBackButton';
import Book from './components/Book'
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"

function Creativity() {

    const [books, setBooks] = useState([]);

    const loadData = () => {
        axios.get('/bookies.json').then((response) => {
            setBooks(response.data);
        }).catch((error)=>{
            console.error(error);
        });
    };
    useEffect(loadData, []);

    const { t } = useTranslation();

    return (
        <div className="w-full h-screen" style={{background:'#F0C66B'}}>
            <motion.div initial={{y: -250}} animate={{ y: 0}} transition={{duration:0.5}} style={{fontSize:'150px', color:"#333333"}} className="w-full text-center pt-12 gotica-font">
                {t('Kūryba')}
            </motion.div>
            <div className=" w-11/12 mx-auto overflow-y-auto mt-20 flex gap-9 pb-4">
                {books.map(((data, key)=>(
                    <Book delay={key*0.1} title={data.name} index={key} key={key} image={data.image}/>
                )))}
                
            </div>
            <motion.div initial={{y: 200}} animate={{ y: 0}} transition={{duration:0.5}} className='fixed bottom-16 right-16'>
                <GoBackButton link="/simonaityte"/>
            </motion.div>
        </div>
    );
}

export default Creativity;