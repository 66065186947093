import React from 'react';
const Icon = (props) => {
  return (
    <div className="text-center mb-2">
      <img className="m-auto h-40" src={props.icon} alt={props.icon}></img>
    </div>

  )
}

export default Icon;
