import AudioBtn from "./Audio";
import Waveform from './Waveform'
import {useState} from 'react'
import { motion } from "framer-motion"
function Item(props) {
    
    const [playing, setPlaying] = useState(false);


    return (
        <motion.li
        initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.5, delay:props.delay}}
        style={{fontSize:'35px', borderRadius:"35px", color:'#333333', height:"85px", background:'#C5D8E6'}} className="relative flex items-center my-4 mx-2 px-5 w-11/12 list-none">
            {!playing ? <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.5}} >{props.text}</motion.div> : null}
            <Waveform audio={props.audio} isPlaying={playing}/>
            <AudioBtn {...props} setPlay={setPlaying} isPlaying={playing}/>
        </motion.li>
    );
}

export default Item;