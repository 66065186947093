import React from 'react';
import { useNavigate } from 'react-router-dom';

const Button = (props) => {

  const navigate = useNavigate();
  function handleClick() {
    navigate(props.url);
  }

  return (
    <button style={{width:'420px', borderRadius:'15px', fontSize:'70px', color:'#333333'}} onClick={handleClick} className='h-24 bg-white gotica-font'>{props.text}</button>
  )
}

export default Button;
