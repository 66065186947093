import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"

function Book(props) {

    const nav = useNavigate();
    const _handleClick = ()=> {
        nav(`/simonaityte/creativity/book/${props.index}`)
    }

    return ( 
        <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{ delay:props.delay}} className="shrink-0" onClick={_handleClick} style={{width:"350px", height:"500px"}}>
            <img src={props.image} alt={props.title}/>
        </motion.div>
     );
}

export default Book;