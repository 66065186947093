import React from 'react';
import { useTranslation } from 'react-i18next'
import { motion } from "framer-motion"

const LanguageButton = (props) => {
  const { i18n } = useTranslation()

  const _handleClick = ()=> {
    i18n.changeLanguage(props.lang)
  }

  return (
    <motion.div 
    initial={{ opacity: 0}}
    animate={{ opacity: 1}}
    transition={{ duration: 0.5 }}
    style={props.style} onClick={_handleClick} className="fixed rounded-full flex items-center z-50 justify-center pb-2 bg-white w-28 h-28">
          {props.title}
    </motion.div>
  )
}

export default LanguageButton;
