import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
const Title = () => {
  const { t } = useTranslation();

  return (
    <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} transition={{duration:0.5}} className='absolute mx-16  inset-x-0 px-40 gotica-font mt-12 text-white z-30 text-center' style={{fontSize:'85px'}}>
        {t('Interaktyvūs pokalbiai su Mažosios Lietuvos metraštininke, rašytoja Ieva Simonaityte')}
    </motion.div>
  )
}

export default Title;