import React from 'react';
import Divider from './components/Divider';
import Title from './components/Title';
import GameButton from './components/GameButton'
import LanguageButton from './components/LanguageButton';
import { useTranslation } from 'react-i18next';

const Header = () => {

    const { t } = useTranslation();

    return (
        <div className='relative' style={{width:"1920px", height:"1080px"}}>
            <Title></Title>
            <Divider  url="/simonaityte" bg="/images/simonaityte.jpg" buttonText={t('Apie rašytoją')} icon="/images/icon1.png"></Divider>
            <Divider url="/sculpture" bg="/images/statue.jpg" buttonText={t('Apie skulptūrą')} icon="/images/icon2.png"></Divider>
            <GameButton></GameButton>
            <LanguageButton title="LT" lang="lt" style={{top:"80px", right:"80px", fontSize:'70px'}}></LanguageButton>
            <LanguageButton title="EN" lang="en" style={{top:"80px", left:"80px", fontSize:'70px'}}></LanguageButton>
        </div>
        )
}
export default Header;